@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,600,700');
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.grid-container {
   display: grid;
 
   grid-template-columns: 20% 1fr;
   grid-template-rows: 72px 1fr;
 
   grid-template-areas:
 
   "sidebar head head head"
   "sidebar main main main"
   "sidebar main main main"
   "sidebar main main main";
 
   /* grid-container height = 100% of viewport height */
   height: 100vh;

   
}

.grid-sidebar {
  grid-area: sidebar;
}

.content {
  grid-area: main;
  background-color: gray;
 
}
.header {
  grid-area: head;
  box-shadow: 0 1px 0 0  #eaedf3;
  background-color: lightblue;
}


@media screen and (max-width: 675px) {
  .grid-container {
    grid-template-columns: 1fr;
    grid-template-rows: 72px 1fr;
    grid-template-areas:
    "head"
    "main";
  }
}